/* ==========================================================================
   #Links
   ========================================================================== */

a {
  color: $color-text;
  font-weight: $font-weight-bold;
  transition: color $global-transition;
  text-decoration: none;

  &:hover,
  &:focus {
    text-decoration: none;
  }

}
