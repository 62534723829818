/* ==========================================================================
   #HIDE
   ========================================================================== */

/**
 * Hide only visually, but have it available for screen readers:
 * http://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 */

.u-hidden-visually {
  @include inuit-hidden-visually();
}

/**
 * Hide visually and from screen readers.
 */

.u-hidden {
  display: none !important;
}

.u-hidden#{$inuit-widths-breakpoint-separator}mobile {
  @include mq($until: tablet) {
    display: none !important;
  }
}

.u-hidden#{$inuit-widths-breakpoint-separator}tablet {
  @include mq($until: desktop) {
    display: none !important;
  }
}

.u-hidden-from#{$inuit-widths-breakpoint-separator}tablet {
  @include mq($from: tablet) {
    display: none !important;
  }
}

.u-hidden-from#{$inuit-widths-breakpoint-separator}desktop {
  @include mq($from: desktop) {
    display: none !important;
  }
}

/**
 * Make transparent
 */

.u-transparent {
	opacity: 0;
}
