.c-button {
  display: inline-block;
  vertical-align: middle;
  font: inherit;
  text-align: center;
  margin: 0;
  cursor: pointer;
  transition: none;
  border-radius: 0;
  border: 1px solid transparent;
  background: $color-black;
  color: $color-primary;
  font-weight: $font-weight-bold;
  line-height: 1;
  padding: $inuit-global-spacing-unit-small;
  font-size: $inuit-global-font-size;
  width: $button-width;
  max-width: 100%;

  &,
  &:hover,
  &:active,
  &:focus {
    text-decoration: none; /* [4] */
  }

  &:hover,
  &:focus {
    outline: none; /* [4] */
  }

  &[disabled] {
    cursor: default;

    &,
    &:hover,
    &:active,
    &:focus {
      color: $color-black;
      border-color: $color-black;
      background: #c7c7c7;
    }
  }
}

.c-button--primary, .c-button--ghost {
  line-height: 40px - $inuit-global-spacing-unit-small * 2 - 2px; // 40px - vertical padding - vertical border
}

.c-button--primary {
  background: $color-black;
  color: $color-primary;

  &:hover, &:focus {
    border-color: $color-black;
    background-color: $color-primary;
    color: $color-black;
  }
}

.c-button--ghost {
  border-color: $color-black;
  background: $color-white;
  color: $color-text;

  &:hover, &:focus {
    border-color: $color-black;
    background-color: $color-primary;
    color: $color-black;
  }
}

.c-button--icon {
  width: auto;
  display: flex;
  align-items: center;

  .c-icon {
    display: block;
    margin-right: $gutter-small;
  }

  .c-icon--right {
    margin-right: 0;
    margin-left: $gutter-small;
  }
}

.c-button--close {
  background: transparent;
  color: $color-black;
  border: none;
  position: relative;
  font-size: 24px;
  line-height: 1;
  width: 24px;
  height: 24px;
  font-weight: normal;
  padding: 0;
}

.c-button--scroll {
  background: transparent;
  color: $color-black;
  border: none;
  width: auto;
  display: flex;
  align-items: center;
  padding: 0;

  .c-icon {
    background: $color-white;
    display: block;
  }

  &--top-right {
    position: absolute;
    top: 0;
    right: $gutter-small;
  }

  &:hover, &:focus {
    background: transparent;

    .c-icon {
      background: $color-primary;
    }
  }
}