.u-position-relative {
  position: relative;
}

.u-position-absolute {
  position: absolute;
}

.u-position-relative#{$inuit-widths-breakpoint-separator}tablet {
  @include mq($from: tablet) {
    position: relative;
  }
}

.u-top-0 {
  top: 0;
}

.u-z-index-1 {
  z-index: 1;
}
