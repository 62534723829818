/* ==========================================================================
   #SHARED
   ========================================================================== */

/**
 * Shared declarations for certain elements.
 */

/**
 * Always declare margins in the same direction:
 * csswizardry.com/2012/06/single-direction-margin-declarations
 */

address,
h1, h2, h3, h4, h5, h6,
blockquote, p, pre,
dl, ol, ul,
hr,
table,
fieldset {
  margin-bottom: $inuit-global-spacing-unit-small;

  //&:last-child {
  //  margin-bottom: 0;
  //}

}

/**
 * Consistent indentation for lists.
 */

dd, ol, ul {
  margin-left: 0;
  padding-left: 20px;
}

hr {
  margin: ($inuit-global-spacing-unit-small * 2) 0;
  border: none;
  border-top: 1px solid $color-black;
}