/*
* ROME DATEPICKER
*/
.c-datepicker {
  border: 1px solid $color-black;
  border-radius: 0 0 $global-radius $global-radius;
  padding: $gutter-small;
  color: $color-black;
  transform: translateY(-1px);
  background: #fff;
}

.c-datepicker__wrapper {
  display: none;
  width: 300px;
  position: absolute;
  //top: 100% !important;
  //left: 0 !important;
  z-index: 100;
}

// arrows
.c-datepicker__container {
  position: relative;
}

.c-datepicker__prev,
.c-datepicker__next {
  border: 1px solid $color-black;
  width: 25px;
  height: 25px;
  padding: 2px;
  //width: 10px;
  //height: 10px;
  //background: url("../public/assets/images/arrow-left.svg") no-repeat 0 0;
  background-size: contain;
  position: absolute;
  top: 7px;
  left: 0;

  &:before {
    content: '❯'
  }

  &:disabled {
    cursor: default;
    opacity: 0.2;
  }

  &:hover {
    background: $color-primary;
  }
}

.c-datepicker__prev {
  //transform: rotate(180deg);
  left: $gutter-small;

  &:before {
    content: '❮'
  }

  //&:not(:disabled):hover {
  //  transform: rotate(90deg) translateY(3px);
  //}
}

.c-datepicker__next {
  //transform: rotate(-90deg);
  left: auto;
  right: $gutter-small;

  //&:not(:disabled):hover {
  //  transform: rotate(-90deg) translateY(3px);
  //}
}

// months
.c-datepicker__month {
  text-align: center;
  padding: $gutter-small 0;
  font-weight: bold;
}

// day
.c-datepicker__day {
  padding: $gutter-tiny;
  text-align: center;
  border-radius: 0;
  cursor: pointer;

  &:hover {
    background: $color-primary;
    color: $color-black;
  }

  &--selected {
    background: $color-black;
    color: $color-primary;
  }

  &--disabled {
    cursor: default;
    position: relative;
    text-indent: -20000px;

    &:after {
      content: "";
      position: absolute;
      width: 10px;
      height: 10px;
      background: $color-primary;
      border-radius: 50%;
      opacity: 0.05;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
    }
  }

  &--next,
  &--prev {
    opacity: 0.4;
  }
}
