.u-color-primary {
  color: $color-primary;
}

.u-color-gray-light {
  color: $color-gray-light;
}

.u-color-success {
  color: $color-success;
}

.u-color-error {
  color: $color-error;
}