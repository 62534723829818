/* ==========================================================================
   #PAGE
   ========================================================================== */

/**
 * Simple page-level setup.
 *
 * 1. Set the default `font-size` and `line-height` for the entire project,
 *    sourced from our default variables. The `font-size` is calculated to exist
 *    in ems, the `line-height` is calculated to exist unitlessly.
 * 2. Force scrollbars to always be visible to prevent awkward ‘jumps’ when
 *    navigating between pages that do/do not have enough content to produce
 *    scrollbars naturally.
 * 3. Ensure the page always fills at least the entire height of the viewport.
 * 4. Better font rendering
 *    http://stackoverflow.com/a/17927764
 * 5. Set the default `font-family` for the entire project.
 * 6. Remove scrollbar on mobile when using 100vh
 *    https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
 */

:root {
  --vh: 1vh; /* [6] */
}

//body, html {
//  user-zoom: fixed;
//  -ms-touch-action: none;
//  touch-action: none;
//}

html {
  font-size: ($inuit-global-font-size / 16px) * 1em; /* [1] */
  line-height: $inuit-global-line-height / $inuit-global-font-size; /* [1] */
  min-height: 100%; /* [3] */
  -webkit-font-smoothing: antialiased; /* [4] */
  -moz-osx-font-smoothing: grayscale; /* [4] */
  font-family: $font-family-global;  /* [5] */
  background: $color-background;
  color: $color-text;
  //height: 100%;
  -webkit-text-size-adjust: none;
}

body {
  //display: flex;
  //flex-direction: column;
  height: 100%;
  min-height: 100%;

  &.modal-open {
    position: fixed;
    width: 100%;
  }
}

.app {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  //user-zoom: fixed;
  //-ms-touch-action: none;
  //touch-action: none;
}

main {
  @include mq($from: desktop) {
    overflow: hidden;
  }
}

.fade-leave,
.fade-enter-to {
  opacity: 1;
  transition: opacity $time $ease-out;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}