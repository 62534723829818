.c-number-connectors {
  //display: flex;
  //flex-wrap: wrap;
  margin-right: -$gutter-small*2;
  width: 100%;
}

.c-number-connectors__item {
  margin-bottom: $gutter-small;
  padding-right: $gutter-small*2;
  float: left;

  @include mq($from: desktop) {
    //width: (100/13) * 1%;

    &:nth-of-type(14) {
      clear: left;
    }
  }

  .c-form__input {
    width: 48px;
    height: 48px;
    max-width: 100%;
    padding: $gutter-small;
  }

  .c-form__error {
    display: none;
  }
}
