.c-grid {
  position: relative;
  width: 100%;
}

// Cells

.c-grid__cell {
  user-select: none;
}

.c-grid__cell--input, .c-grid__cell--solution {
  .c-grid__cell__text, .c-grid__cell__placeholder {
    font-family: $font-family-monospace;
  }
  .c-grid__cell__text {
    font-weight: $font-weight-bold;
  }
}

.c-grid__cell--input {
  cursor: pointer;
}

.c-grid__cell--invalid {
  .c-grid__cell__text {
    fill: $color-error;
  }
}

.c-grid__cell--highlighted {
  .c-grid__cell__rect {
    fill: $color-cell-highlighted;
  }
  .c-grid__cell__placeholder {
    fill: $color-text;
  }
}

.c-grid__cell--letter {
  .c-grid__cell__rect {
    stroke-width: 0;
  }
}

.c-grid__cell--letter--active {
  //.c-grid__cell__rect {
  //  fill: $color-primary;
  //}
  .c-grid__cell__text {
    font-weight: $font-weight-bold;
  }
}

.c-grid__cell--solution  {
  .c-grid__cell__rect {
    fill: $color-primary;
  }
  .c-grid__cell__text {
    font-weight: $font-weight-bold;
  }
}

.c-grid__cell--connected {
  .c-grid__cell__rect {
    fill: $color-cell-connected;
  }
  .c-grid__cell__text {
    fill: $color-text;
  }
  .c-grid__cell__placeholder {
    fill: $color-text;
  }
}

.c-grid__cell--active {
  .c-grid__cell__rect {
    fill: $color-cell-active;
  }
  .c-grid__cell__text {
    fill: $color-white;
  }
  .c-grid__cell__placeholder {
    fill: $color-white;
  }
}

.c-grid__cell__rect {
  fill: $color-white;
  stroke-width: 1;
  stroke: $color-black;
}

.c-grid__cell__text {
  font-style: normal;
}

.c-grid__cell__placeholder {
  fill: $color-cell-placeholder;
  font-size: 0.75rem;
  transition: font-size 0.15s ease;
}

.c-grid__cell__placeholder--small {
  font-size: 0.4rem;
}


// Input

.c-grid__input {
  border: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  background-color: transparent;
  color: $color-white;
  font-size: 16px;
  font-weight: 800;
  text-anchor: middle;
  text-transform: uppercase;
  -webkit-user-select: auto;

  &:focus {
    outline: none;
  }
}

.c-grid__input__wrapper {
  position: absolute;
}