/* ==========================================================================
   #FORMS
   ========================================================================== */

.c-form {
  display: block;
}

.c-form--single-field {
  position: relative;
}

.c-form__group {
  position: relative;
  margin-bottom: $gutter-small;
  text-align: left;
}

.c-form__group + .c-form__group {
  margin-top: $gutter-small;
}

.c-form__group.is-valid {
  .c-form__input {
  }
}

.c-form__group.is-invalid {
  .c-form__input {
    border-color: $color-error;

    &:focus {
      box-shadow: 0 0 15px 0 rgba($color-error, 0.15);
    }
  }
}

.c-form__group--with-icon {
  .c-form__input {
    padding-right: $inuit-global-spacing-unit * 3;
  }
}

.c-form__label {
  color: $color-black;
}

.c-form__input {
  display: block;
  padding: $inuit-global-spacing-unit;
  width: 100%;
  border: 1px solid $color-black;
  background: $color-background;
  transition: border-color $time $ease-out;
  font-size: 16px;

  &:focus {
    outline: none;
    border-color: $color-primary;
  }
}

.c-form__input--rounded {
  border: 1px solid $color-black;
  border-radius: $global-radius;
  background: #fff;

  &:focus {
    outline: none;
    box-shadow: 0 0 15px 0 rgba($color-primary, 0.15);
  }
}

.c-form__input__wrapper {
  position: relative;
}

.c-form__input__icon {
  color: $color-primary;
  position: absolute;
  top: 26px;
  right: $inuit-global-spacing-unit;
  transform: translateY(-50%);
}

.c-form__radio__label,
.c-form__checkbox__label {
  color: $color-black;
  position: relative;
  display: block;
  padding-left: 25px + $gutter-small;
}

.c-form__radio__input,
.c-form__checkbox__input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.c-form__checkbox__checkmark {
  color: transparent;
  background: $color-background;
  position: absolute;
  top: 0;
  left: 0;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid $color-black;
  user-select: none;
}

.c-form__checkbox__input {
  &:checked {
    +.c-form__checkbox__label {
      .c-form__checkbox__checkmark {
        background: $color-primary;
        color: $color-black;
      }
    }
  }
}

.c-form__error {
  color: $color-error;
  padding-top: $gutter-tiny;
  //font-weight: $font-weight-medium;
}