.c-icon {
  display: inline-block;
}

.c-icon--arrow {
  width: 6px;
  height: 12px;
}

.c-icon--arrow-square {
  width: 26px;
  height: 26px;
}

.c-icon--trash {
  width: 16px;
  height: 16px;
}