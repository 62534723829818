/* ==========================================================================
   #Selection
   ========================================================================== */

::-moz-selection {
  background: rgba($color-primary, 0.99);
  color: $color-black;
  text-shadow: none;
}

::selection {
  background: rgba($color-primary, 0.99);
  color: $color-black;
  text-shadow: none;
}
