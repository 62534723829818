.c-notification {
  //border: 1px solid $color-black;
  background: $color-primary;
  color: $color-black;
  margin-bottom: $inuit-global-spacing-unit;
  padding: $gutter-tiny $gutter-small;
}

.c-notification--error {
  background: $color-error;
  color: $color-white;
}

.c-notification--success {
  background: $color-success;
}

.c-notification--snackbar {
  position: fixed;
  bottom: $gutter;
  right: $gutter;
  z-index: $zindex-tooltip;
  box-shadow: 0 0 $gutter-small 0 rgba(0, 0, 0, 0.15);
}