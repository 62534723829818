.c-popup {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  min-height: calc(var(--vh, 1vh) * 100);
  z-index: 100;

  display: flex;
  justify-content: center;
  align-items: flex-end;
  -webkit-backface-visibility: hidden;

  @include mq($from: tablet) {
    align-items: center;
    padding: $gutter-small;
  }
}

.c-popup--large {
  .c-popup__content {
    @include mq($from: tablet) {
      width: 1000px;
    }
  }
}

.c-popup__backdrop {
  position: absolute;
  top: -100vh;
  left: 0;
  right: 0;
  bottom: -100vh;
  background: rgba($color-black, 0.5);
}

.c-popup__content {
  background: $color-white;
  position: relative;
  padding: $gutter;
  width: 100%;
  max-width: 100%;

  @include mq($from: tablet) {
    width: auto;
  }
}

.c-popup__close {
  position: absolute;
  top: $gutter-small;
  right: $gutter-small;
}
