/* ==========================================================================
   #BUTTONS
   ========================================================================== */

/**
 * Implements better button defaults
 */

button {
  background: none;
  border: 1px solid $color-gray;
  cursor: pointer;

  &:focus {
    outline: thin dotted;
  }
}
