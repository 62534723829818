.u-border {
  border: 1px solid $color-black;
}

.u-border-bottom {
  border-bottom: 1px solid $color-black;
}

.u-border--primary {
  border-color: $color-primary;
}

.u-border--gray-lighter {
  border-color: $color-gray-lighter;
}

.u-border-top-none {
  border-top: none !important;
}

.u-border-bottom-none#{$inuit-widths-breakpoint-separator}tablet {
  @include mq($from: tablet) {
    border-bottom: none;
  }
}