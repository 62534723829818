.c-wysiwyg {

}

.c-wysiwyg__menu__item {
  border: 1px solid $color-black;
  border-bottom: none;
  padding: $gutter-tiny;

  &:not(:first-of-type) {
    border-left: none;
  }

  &.is-active {
    background: $color-yellow;
  }
}

.c-wysiwyg__content {
  > div {
    &:focus {
      outline: none;
    }
  }
}