/* ==========================================================================
   #BACKGROUND
   ========================================================================== */

/**
 * Utility classes to modify background colors
 */

.u-bg-white {
  background-color: $color-white;
}

.u-bg-grey-lighter {
  background-color: $color-gray-lighter;
}

.u-bg-primary {
  background-color: $color-primary;
}

.u-bg-none#{$inuit-widths-breakpoint-separator}tablet {
  @include mq($from: tablet) {
    background: none;
  }
}