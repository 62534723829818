.c-blocklist {

}

.c-blocklist__item {
  display: flex;
  text-decoration: none;
  border: 1px solid $color-black;
  margin-bottom: $gutter-small;
  background-color: $color-white;

  &:hover, &:focus {
    background-color: $color-primary;

    .c-date {
      background: $color-black;
      color: $color-primary;
    }
  }
}

.c-blocklist__item__label {
  padding: $gutter-tiny * 3 $gutter-tiny * 4;
}

.c-blocklist__item__arrow {
  width: 40px;
  background: $color-black;
  color: $color-primary;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
}