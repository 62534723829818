.o-flex {
    display: flex;

    &\@desktop {
      @include mq($from: desktop) {
        display: flex;
      }
    }

    &\@tablet {
      @include mq($from: tablet) {
        display: flex;
      }
    }

    &\@mobile {
      @include mq($from: mobile) {
        display: flex;
      }
    }

    &\@to-tablet {
        @include mq($until: tablet) {
            display: flex;
        }
    }
}

.o-inline-flex {
  display: inline-flex;
}

.o-flex--column {
    flex-direction: column;
}

.o-flex--center {
    justify-content: center;
}

.o-flex--left {
    justify-content: flex-start;
}

.o-flex--justify-flex-end {
    justify-content: flex-end;
}

.o-flex--justify-space-between {
    justify-content: space-between;
}

.o-flex--end {
    justify-content: flex-end;
}

.o-flex--align-center {
  align-items: center;
}

.o-flex--align-start {
    align-items: flex-start;
}

.o-flex--align-end {
    align-items: flex-end;
}

.o-flex--align-stretch {
    align-items: stretch;
}

.o-flex--grow {
    flex-grow: 1;
}

.o-flex--equal {
    flex: 1 1 auto;
}

.o-flex--double {
    flex: 2 2 auto;
}

.o-flex--align-self-end {
    align-self: flex-end;
}

.o-flex--align-self-start {
  align-self: flex-start;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
     /* IE10+ CSS styles go here */
     .o-flex--column {
         > * {
             max-width: 100%;
         }
     }

     .o-flex--column.o-flex--center {
       display: table-cell;
       vertical-align: middle;
     }
}
