///* ========================================================================
//   #GLOBAL
//   ======================================================================== */
// The global settings file contains any project-wide variables; things that
// need to be made available to the entire codebase.

$global-radius: 5px;
$global-opacity: 0.75;

/**
 * Animation
 */

$time-small: 0.220s;
$time: 0.440s;
$time-large: 0.660s;
$time-xlarge: 0.880s;

$ease-out: cubic-bezier(0.77, 0, 0.175, 1);
$ease-out-quad: cubic-bezier(0.25, 0.46, 0.45, 0.94);
$global-transition: $time $ease-out-quad;

/**
 * Color
 */

$color-black: #000;
$color-white: #fff;

$gray-darker: #222;
$gray-dark: #333;
$color-gray: #999;
$color-gray-light: #99A6AD;
$color-gray-lighter: #E3E3E2;

$color-cell: $color-white;
$color-cell-highlighted: #DAE6E9;
$color-cell-active: $color-black;
$color-cell-connected: #ADBEB8;
$color-cell-placeholder: #575757;

$color-yellow: #F3ED63;
$color-red: #FF0000;
$color-background: $color-white;

$color-primary: $color-yellow;
$color-text: $color-black;
$color-error: $color-red;
$color-success: #56c55b;

$notices: (
  error: $color-error,
  success: $color-success,
);

/**
 * Container
 */

$inuit-wrapper-width: 1000px;

$button-width: 300px;

/**
 * Typography
 * Local fonts are loaded in the file _generic.scaffolding.scss
 */

$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-bold: 600;
$font-weight-heavy: 700;

$font-family-benton-sans: 'benton-sans';
$font-family-monospace: 'pt-mono', monospace;

$font-family-global: $font-family-benton-sans, "Arial", "Helvetica Neue", sans-serif;
$font-family-headings: $font-family-benton-sans, "Arial", "Helvetica Neue", sans-serif;

$font-weight-headings: $font-weight-bold;

$inuit-global-font-size: 14px;

$h1-font-sizes: (
  null: (28px, 1.22)
);

$h2-font-sizes: (
  null: (24px, 1.22)
);

$h3-font-sizes: (
  null: 16px
);

$inuit-line-height-h3: 1.22;
$inuit-line-height-h4: 1.22;
$inuit-line-height-h5: $inuit-global-line-height;
$inuit-line-height-h6: $inuit-global-line-height;

/**
 * MQ Breakpoints
 */

$mq-breakpoints: (
  mobile: 480px,
  tablet: 768px,
  desktop: 1024px,
  wide: 1440px
);

/**
 * Spacing aliases
 */

$gutter-tiny: $inuit-global-spacing-unit-tiny;
$gutter-small: $inuit-global-spacing-unit-small;
$gutter: $inuit-global-spacing-unit-medium;
$gutter-large: $inuit-global-spacing-unit-large;
$gutter-huge: $inuit-global-spacing-unit-huge;

$gutter-wrapper: $inuit-global-spacing-unit-tiny * 2;

/*
 * Responsive object & utility classes
 */

$responsive-spacing-classes: true;
$responsive-align-classes: true;

/**
 * Spacing utility classes
 */

$inuit-spacing-directions: (
  null: null,
  "-top": "-top",
  "-right": "-right",
  "-bottom": "-bottom",
  "-left": "-left",
  "-horizontal": "-left" "-right",
  "-vertical": "-top" "-bottom",
);

$inuit-spacing-properties: (
  "padding": "padding",
  "margin": "margin",
);

$inuit-spacing-sizes: (
  null: $inuit-global-spacing-unit-medium,
  '-tiny': $inuit-global-spacing-unit-tiny,
  '-small': $inuit-global-spacing-unit-small,
  '-medium-small': $inuit-global-spacing-unit-medium-small,
  '-medium': $inuit-global-spacing-unit-medium,
  '-large': $inuit-global-spacing-unit-large,
  '-huge': $inuit-global-spacing-unit-huge,
  '-none': 0
);

$inuit-widths-breakpoint-separator: \@;

/**
 * Z-index
 */

$zindex-navbar: 900 !default;
$zindex-dropdown: 1000 !default;
$zindex-sticky: 1020 !default;
$zindex-fixed: 1030 !default;
$zindex-modal-backdrop: 1040 !default;
$zindex-modal: 1050 !default;
$zindex-popover: 1060 !default;
$zindex-tooltip: 1070 !default;