/* ==========================================================================
   #HEADINGS
   ========================================================================== */

/**
 * Redefine all of our basic heading styles against utility classes so as to
 * allow for double stranded heading hierarchy, e.g. we semantically need an H2,
 * but we want it to be sized like an H1:
 *
 *     <h2 class="u-h1"></h2>
 *
 */

.u-h1 {
  @include inuit-h1($important: true);
}

.u-h2 {
  @include inuit-h2($important: true);
}

.u-h3 {
  @include inuit-h3($important: true);
}

.u-h4 {
  @include inuit-h4($important: true);
}

.u-h5 {
  @include inuit-h5($important: true);
}

.u-h6 {
  @include inuit-h6($important: true);
}
