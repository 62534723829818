// Hint

.c-hint {
  display: flex;
  align-items: flex-start;
  background: $color-white;
  font-weight: $font-weight-bold;
  padding: $gutter-tiny $gutter-tiny * 2;
}

.c-hint__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 80px;
  padding: $inuit-global-spacing-unit-medium;

  @include mq($from: tablet) {
    display: none;
  }
}

.c-hint__letter {
  padding-right: $gutter-tiny * 3;
}


// Hint list

.c-hint-list {

}

.c-hint-list__item {
  cursor: pointer;
  text-align: left;
  border: 1px solid transparent;
  padding: $gutter-tiny $gutter-tiny * 2;
  display: flex;
  align-items: flex-start;
  width: 100%;
  line-height: 1.3;
  min-height: 40px;

  &:hover, &:focus {
    outline: none;
  }
}

.c-hint-list__item__letter {
  padding-right: $gutter-tiny * 3;
  width: 24px;
  flex-shrink: 0;
  text-align: center;
}

.c-hint-list__item--highlighted {
  background: $color-primary;
  border-color: $color-black;

  .c-hint-list__item__letter {
    font-weight: $font-weight-bold;
  }
}