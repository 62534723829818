.c-table, .c-table__column {
  //border: 1px solid $color-black;
}

.c-table {

}

.c-table__row {
  &:nth-child(odd) {
    background: $color-gray-lighter;
  }
}

.c-table__column {
  padding: $gutter-tiny;
  text-align: left;
  border-bottom: 1px solid $color-gray;
}

.c-table__column--head {
}

.c-table__row--link {
  cursor: pointer;

  &:hover, &:focus {
    background: $color-primary;
  }
}