.fade-leave,
.fade-enter-to {
  opacity: 1;
  transition: opacity $time $ease-out;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.fade-in-top-enter-active, .fade-in-top-leave-active {
  transition: opacity $time $ease-out, transform $time $ease-out;
}

.fade-in-top-enter,
.fade-in-top-leave-to {
  opacity: 0;
  transform: translateY(-100%);
  transition: opacity $time $ease-out, transform $time $ease-out;
}