///* ========================================================================
//   #CORE
//   ======================================================================== */

// This core file sets up inuitcss’ most important setup variables. They
// underpin a lot of how the framework functions and should be modified and
// preconfigured with caution.

// Base typographical styles and baseline grid. You need to define these values
// in pixels: inuitcss will convert them to more appropriate units.

$inuit-global-font-size: 16px !default;
$inuit-global-line-height: 24px !default;

// Spacing values are determined based on your project’s global line height (i.e
// your baseline grid). It is not recommended that you modify these following
// variables (it can break your vertical rhythm), but if you need to, you can.

$inuit-global-spacing-unit: 4px;

// How many times larger/smaller than the default should our spacing unit
// variants be?

$inuit-global-spacing-unit-factor-tiny: 0.25 !default;
$inuit-global-spacing-unit-factor-small: 0.5 !default;
$inuit-global-spacing-unit-factor-medium: 0.66 !default;
$inuit-global-spacing-unit-factor-large: 2 !default;
$inuit-global-spacing-unit-factor-huge: 4 !default;

// Check that the chosen font rules are pixel numbers.

@each $_inuit-font-globals in $inuit-global-font-size $inuit-global-line-height {

  @if (type-of($_inuit-font-globals) == number) {

    @if (unit($_inuit-font-globals) != "px") {
      @error "`#{$_inuit-font-globals}` needs to be a pixel value.";
    }

  } @else {
    @error "`#{$_inuit-font-globals}` needs to be a number.";
  }

}

// Check that the chosen size factors are unitless numbers.

@each $_inuit-spacing-unit in $inuit-global-spacing-unit-factor-tiny $inuit-global-spacing-unit-factor-small $inuit-global-spacing-unit-factor-large $inuit-global-spacing-unit-factor-huge {

  @if (type-of($_inuit-spacing-unit) == number) {

    @if (unitless($_inuit-spacing-unit) == false) {
      @error "`#{$_inuit-spacing-unit}` needs to be unitless.";
    }

  } @else {
    @error "`#{$_inuit-spacing-unit}` needs to be a number.";
  }

}

// Private/framework-only reassignment. Do not alter anything below.

$inuit-global-spacing-unit-tiny: 4px;
$inuit-global-spacing-unit-small: 8px;
$inuit-global-spacing-unit-medium-small: 16px;
$inuit-global-spacing-unit-medium: 24px;
$inuit-global-spacing-unit-large: 48px;
$inuit-global-spacing-unit-huge: 72px;

//$inuit-global-spacing-unit-tiny: round($inuit-global-spacing-unit * $inuit-global-spacing-unit-factor-tiny);
//$inuit-global-spacing-unit-small: round($inuit-global-spacing-unit * $inuit-global-spacing-unit-factor-small);
//$inuit-global-spacing-unit-medium: round($inuit-global-spacing-unit * $inuit-global-spacing-unit-factor-medium);
//$inuit-global-spacing-unit-large: round($inuit-global-spacing-unit * $inuit-global-spacing-unit-factor-large);
//$inuit-global-spacing-unit-huge: round($inuit-global-spacing-unit * $inuit-global-spacing-unit-factor-huge);
